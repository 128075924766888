import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.scss"
import { query } from "../pages/using-typescript"


const NavMenu = ({ menuLinks }) => (
  <nav id={'navMenu'} className={'navMenu'}>
    <ul>
      {menuLinks.map(link => (
        <li
          key={link.name}
          className={'navBtn'}
        >
          <Link to={link.link}>
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
)

// NavMenu.propTypes = {
//   siteTitle: PropTypes.string,
// }

// NavMenu.defaultProps = {
//   siteTitle: ``,
// }

export default NavMenu