import { Link } from "gatsby"
import PropTypes, { func } from "prop-types"
import React from "react"
import "./header.scss"
import { query } from "../pages/using-typescript"
import NavMenu from "./navigation_menu"


const Header = ({ siteTitle, menuLinks }) => (
  <header>
    <div className={'navigation'}>
      <h1>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            zIndex: `1`,
          }}
        >
          BB
          {/*{siteTitle}*/}
        </Link>
      </h1>
      <div id={'menuBtn'} onClick={menu} className={'menuBtn'}>
        .menuBtn
      </div>
    </div>
    <NavMenu menuLinks={menuLinks} />
  </header>
)

function menu() {
  var menuBtn = document.getElementById('menuBtn');
  var navMenu = document.getElementById('navMenu');
  if (menuBtn.className === 'menuBtn') {
    menuBtn.innerText = '.closeBtn';
    menuBtn.className = 'closeBtn';
    navMenu.classList.add('isOpen');
  } else {
    menuBtn.innerText = '.menuBtn';
    menuBtn.className = 'menuBtn'
    navMenu.classList.remove('isOpen')
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
