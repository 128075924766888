import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Typewriter from "typewriter-effect"
import "../components/_variables.scss"
// import SpotifyApi from "../components/spotify/fetchTrack"

const greetings = ["I'm Branden Bonner.", "I'm a Developer.", "I'm a Designer.", "I'm a Creator."];

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{
      minHeight: '75vh',
      display: 'flex',
      // '-webkit-box-pack': 'center',
      // justifyContent: 'center',
      flexDirection: 'column',
    }}>
      <h1 className={'hello code'}>Hello</h1>
      <div>
        <Typewriter
          // onInit={(typewriter) => {
          //   typewriter.typeString('Hello World!')
          //     .callFunction(() => {
          //       console.log('String typed out!');
          //     })
          //     .pauseFor(2500)
          //     .deleteAll()
          //     .callFunction(() => {
          //       console.log('All strings were deleted');
          //     })
          //     .start();
          // }}
          options={{
            strings: greetings,
            autoStart: true,
            loop: true,

          }}
        />
      </div>
      <p className={'bio_description'}>
        I love to work with Java and JavaScript. I've dabbled in Ruby, Python, and Objective-C. My front end framework of choice is React, but I've had some experience in Flutter. My back end framework of choice is , but i also have experience in Ruby on Rails and ASP.NET.

      </p>
      {/*<SpotifyApi/>*/}
      {/*<SpotifyWebApi>*/}

      {/*</SpotifyWebApi>*/}
    </div>
  </Layout>
)

export default IndexPage
